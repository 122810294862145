<messages>["./Services"]</messages>
<template>
  <v-row class="fill-height px-2" justify="space-between" align="center">
    {{ $t (`status.${status}`) }}
    <v-icon
      v-if="status === VALID"
      color="green darken-3">
      check
    </v-icon>
    <v-icon
      v-if="status === INVALID"
      color="red darken-3">
      cancel
    </v-icon>
    <v-icon
      v-if="status === UNKOWN"
      color="grey">
      help
    </v-icon>

    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          v-if="canEdit"
          icon
          v-on="on"
          @click="$emit('click')">
          <v-icon>edit</v-icon>
        </v-btn>
      </template>
      <span v-t="'general.button.edit'"/>
    </v-tooltip>
  </v-row>
</template>

<script>
  export const VALID = 'Valid'
  export const INVALID = 'Invalid'
  export const UNKOWN = 'Unknown'

  export default {
    props: {
      status: {
        type: String,
        required: true
      },
      canEdit: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        VALID,
        INVALID,
        UNKOWN
      }
    }
  }
</script>
