<messages>["./Services"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-data-table
    :headers="headers"
    :items="trail"
    must-sort
    :items-per-page.sync="pagination.rowsPerPage"
    :sort-by.sync="pagination.sortBy"
    :sort-desc.sync="pagination.descending"
    hide-default-footer
    class="elevation-1">
    <template #item="props">
      <tr>
        <td>
          {{ formatDate (props.item.date) }}
        </td>
        <td>
          <state-transform-visual
            :old="props.item.oldState"
            :new-state="props.item.newState"
            :compact="compactTable"
            :color="color"/>
        </td>
        <td v-if="!veryCompactTable">
          <b>{{ $t ('table.event.label.source') }}: </b>
          {{ props.item.event.source }}
          <br>
          <template v-if="props.item.event.comment">
            <b>{{ $t ('table.event.label.comment') }}: </b>
            {{ props.item.event.comment }}
            <br>
          </template>
          <template v-if="props.item.event.address">
            <b>{{ $t ('table.event.label.address') }}: </b>
            {{ props.item.event.address }}
            <br>
          </template>
          <template v-if="props.item.event.memberID">
            <b>{{ $t ('table.event.label.memberid') }}: </b>
            {{ props.item.event.memberID }}
            <br>
          </template>
          <template
            v-if="props.item.event.effectiveMemberID &&
              props.item.event.effectiveMemberID !== props.item.event.memberID">
            <b>{{ $t ('table.event.label.effectiveMemberID') }}: </b>
            {{ props.item.event.effectiveMemberID }}
            <br>
          </template>
          <template v-if="props.item.event.action">
            <b>{{ $t ('table.event.label.action') }}: </b>
            {{ props.item.event.action }}
            <br>
          </template>
          <template v-if="props.item.emailId">
            <b>{{ $t ('table.event.label.emailId') }}: </b>
            <email-dialog :id="props.item.emailId">
              <template #activator="{ on }">
                <a
                  href=""
                  v-on="on">
                  {{ props.item.emailId }}
                </a>
              </template>
            </email-dialog>
            <br>
          </template>
          <template v-if="props.item.orderId">
            <b>{{ $t ('table.event.label.orderId') }}: </b>
            <order-dialog
              :order-id="props.item.orderId"
              type="emailVerification"
              object-id="N/A">
              <template #activator="{ on }">
                <a
                  href=""
                  v-on="on">
                  {{ props.item.orderId }}
                </a>
              </template>
            </order-dialog>
            <br>
          </template>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import OrderDialog from '@/app/core/components/Order/OrderDialog'
  import EmailDialog from '@/app/core/components/Email/EmailViewDialog'

  import StateTransformVisual from './StateTrasformVisual'

  export default {
    name: 'EmailAuditTrailTable',

    components: {
      OrderDialog,
      StateTransformVisual,
      EmailDialog
    },

    props: {
      trail: {type: Array, required: true}
    },

    data () {
      return {
        pagination: {sortBy: 'date', descending: true, rowsPerPage: -1}
      }
    },

    computed: {
      headers () {
        return [
          {
            text: this.$t ('table.header.date'),
            align: 'left',
            value: 'date',
            sortable: true
          },
          {
            text: this.$t ('table.header.status'),
            align: 'left',
            value: 'status',
            sortable: false
          },
          ...this.veryCompactTable
            ? []
            : [
              {
                text: this.$t ('table.header.event'),
                align: 'left',
                value: 'event',
                sortable: false
              }]
        ]
      },

      veryCompactTable () {
        return this.$vuetify.breakpoint.xs
      },

      compactTable () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },

    mounted () {
      this.$emit ('mounted')
    },

    methods: {
      color (status) {
        if (status === 'Valid') {
          return {color: 'green darken-3', icon: 'check'}
        } else if (status === 'Invalid') {
          return {color: 'red darken-3', icon: 'cancel'}
        } else {
          return {color: 'grey', icon: 'help'}
        }
      }
    }
  }
</script>
