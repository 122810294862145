<messages>["./Services"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-row>
    <base-dialog
      v-model="dialog"
      mw0
      scrollable>
      <template #activator="{ on }">
        <v-btn
          v-t="'view.label.associated'"
          v-on="on"
          @click="fetch()"/>
      </template>
      <v-card>
        <v-card-title>
          <div>
            <div class="text-h5">
              {{ $t ('view.label.associatedTitle', {email: email}) }}
            </div>
            <div
              v-t="'view.label.associatedDescription'"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>
        <v-card-text :style="{height: $vuetify.breakpoint.smAndDown? '100%' : '300px'}">
          <v-slide-y-transition mode="out-in">
            <v-data-table
              :headers="headers"
              :loading="isLoading"
              :no-data-text="isLoading? $t ('general.info.searchInProgress') : $t ('view.table.noentry')"
              :items="data"
              :items-per-page="-1"
              hide-default-footer
              class="elevation-1">
              <template #item="props">
                <tr>
                  <td>
                    <router-link
                      target="_blank"
                      :to="{ name: 'domain.view', params: { name: props.item } }"
                      v-text="props.item"/>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-slide-y-transition>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.back'"
            text
            @click.native="dialog = false"/>
        </v-card-actions>
      </v-card>
    </base-dialog>
  </v-row>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    name: 'AssociatedDomains',

    components: {
      BaseDialog
    },

    props: {
      email: {type: String, required: true}
    },

    data () {
      return {
        dialog: false,
        isLoading: false,
        fetched: false,
        data: [],
        headers: [
          {
            text: this.$t ('view.table.domainname'),
            sortable: false,
            align: 'left'
          }
        ]
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      fetch () {
        if (!this.fetched) {
          this.isLoading = true
          const request = {
            op: 'emailstatus/association',
            params: {
              email: this.email
            },
            cb: data => {
              this.data = data.list
              this.fetched = true
            },
            cbFinal: () => {
              this.isLoading = false
            }
          }
          this.fetchData (request)
        }
      }
    }
  }
</script>
