<messages>["./Services"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-card
    height="100%"
    width="100%">
    <v-card-title primary-title>
      <v-row justify="space-between">
        <v-col>
          <div>
            <div
              v-t="'view.label.status'"
              class="text-h5"/>
            <div
              v-t="'view.label.statusDescription'"
              class="cgwng-subheading"/>
          </div>
        </v-col>
        <v-col v-if="canSend" class="text-right">
          <send-verification-email :address="email">
            <template #activator="{ on }">
              <v-btn color="primary" v-on="on">
                {{ $t (data ? 'view.label.sendEmail.resendMail' : 'view.label.sendEmail.start') }}
              </v-btn>
            </template>
          </send-verification-email>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-slide-y-transition
        mode="out-in"
        :after-enter="afterEnter">
        <v-container
          v-if="isLoading"
          key="loading">
          <v-row>
            <v-progress-linear
              indeterminate
              color="primary"/>
          </v-row>
        </v-container>
        <v-container
          v-else-if="notFound"
          key="notFound">
          <v-alert type="error">
            {{ $t ('view.label.emailNotFound') }}
          </v-alert>
        </v-container>
        <template v-else>
          <v-container key="status">
            <v-row class="mb-4">
              <v-col cols="12" md="6">
                <name-value-block :items="nameValueData"/>
              </v-col>
            </v-row>
            <template v-if="data && data.trail ? data.trail.length > 0 : false">
              <v-row>
                <v-col cols="12">
                  <div
                    v-t="'view.label.trail'"
                    class="text-h5"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <email-audit-trail-table
                    :trail="data.trail"
                    @mounted="tableMounted"/>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </template>
      </v-slide-y-transition>
    </v-card-text>
  </v-card>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters} from 'vuex'

  import NameValueBlock from '@/app/core/components/NameValueBlock'

  import EmailAuditTrailTable from './EmailAuditTrailTable'
  import StatusEditButton, {VALID} from './StatusEditButton'

  import SendVerificationEmail from './components/SendVerificationEmail'

  export default {
    name: 'EmailStatus',

    components: {
      EmailAuditTrailTable,
      NameValueBlock,
      SendVerificationEmail
    },

    props: {
      isLoading: Boolean,
      data: {
        type: Object,
        default: undefined
      },
      canEdit: Boolean,
      notFound: Boolean,
      email: {
        type: String,
        default: undefined
      }
    },

    computed: {
      ...mapGetters ({
        mayManageObject: 'auth/mayManageObject',
        permissions: 'auth/permissions'
      }),

      canSend () {
        const cId = this.data?.clientId
        const isAdmin = this.permissions.includes ('ManageAllObjects')

        return (this.email && !this.data) || ((this.data && this.data?.status !== VALID) &&
          ((cId && this.mayManageObject (cId)) || isAdmin))
      },

      nameValueData () {
        if (!this.data) return []

        return [
          {
            name: this.$t ('view.label.email'),
            value: this.data.email
          },
          {
            name: this.$t ('view.label.status'),
            value: true,
            slotValue: this.$createElement (StatusEditButton, {
              props: {
                status: this.data.status,
                canEdit: this.canEdit
              },
              on: {
                click: () => this.$emit ('edit')
              }
            })
          }
        ]
      }
    },

    mounted () {
      this.$emit ('created', '#status')
    },

    methods: {
      afterEnter () {
        this.$emit ('created', '#status')
      },

      tableMounted () {
        this.$emit ('created', '#status')
      }
    }
  }
</script>
