<messages>["./Services"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-card
    height="100%"
    width="100%">
    <form @submit.prevent="submit">
      <v-card-title primary-title>
        <div>
          <div
            v-t="'view.label.edit'"
            class="text-h5"/>
          <div
            v-t="'view.label.editDescription'"
            class="cgwng-subheading"/>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              {{ $t ('view.label.email') + ':' }}
            </v-col>
            <v-col cols="8">
              {{ data.email }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              {{ $t ('view.label.newStatus') + ':' }}
            </v-col>
          </v-row>
          <v-radio-group
            v-model="status"
            :disabled="isLoading">
            <v-row>
              <v-col cols="12" sm="4">
                <v-radio
                  :label="$t ('status.Unknown')"
                  :disabled="!admin"
                  color="grey"
                  :value="statusOptions.UNKOWN"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-radio
                  :label="$t ('status.Valid')"
                  color="green darken-3"
                  :value="statusOptions.VALID"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-radio
                  :label="$t ('status.Invalid')"
                  :disabled="!admin"
                  color="red darken-3"
                  :value="statusOptions.INVALID"/>
              </v-col>
            </v-row>
          </v-radio-group>
          <v-slide-y-transition mode="out-in">
            <v-row v-if="showActions">
              <v-col cols="12">
                {{ $t ('view.label.actions') + ':' }}
              </v-col>
              <v-col cols="12">
                <associated-domains :email="data.email"/>
              </v-col>
            </v-row>
          </v-slide-y-transition>
          <v-slide-y-transition mode="out-in">
            <v-radio-group
              v-if="showActions"
              v-model="action"
              :disabled="isLoading">
              <v-row>
                <v-col cols="12">
                  <v-radio
                    :label="$t ('action.noAction')"
                    :value="statusOptions.NONE"/>
                </v-col>
                <v-col cols="12">
                  <v-radio
                    :label="$t ('action.suspend')"
                    :value="statusOptions.SUSPEND"/>
                </v-col>
                <v-col cols="12">
                  <v-radio
                    :label="$t ('action.verifyNew')"
                    :value="statusOptions.REVERIFY"/>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-slide-y-transition>
          <v-row>
            <v-col cols="12">
              {{ $t ('view.label.documentation') + ':' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="documentation"
                :disabled="isLoading"
                name="doc"
                :label="$t ('view.label.documentation')"
                persistent-hint
                :hint="$t ('view.label.documentationHint')"
                rows="1"
                auto-grow/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.cancel'"
          text
          :disabled="isLoading"
          @click="cancel()"/>
        <v-btn
          v-t="'general.button.submit'"
          color="primary"
          type="submit"
          :loading="isLoading"/>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
  import AssociatedDomains from './AssociatedDomains'

  const UNKOWN = 'Unknown'
  const VALID = 'Valid'
  const INVALID = 'Invalid'
  const NONE = 'None'
  const SUSPEND = 'Suspend'
  const REVERIFY = 'Reverify'

  export default {
    name: 'EmailStatusEdit',

    components: {
      AssociatedDomains
    },

    props: {
      data: {type: Object, required: true},
      isLoading: {type: Boolean, required: true},
      admin: Boolean
    },

    data () {
      return {
        status: null,
        documentation: '',
        action: NONE
      }
    },

    computed: {
      statusOptions () {
        return {
          UNKOWN,
          VALID,
          INVALID,
          NONE,
          SUSPEND,
          REVERIFY
        }
      },

      showActions () {
        return this.data.status === 'Valid' && this.status !== 'Valid'
      }
    },

    created () {
      this.status = this.data.status
    },

    mounted () {
      this.$emit ('created', '#edit')
    },

    methods: {
      submit () {
        this.$emit ('submit', {
          email: this.data.email,
          newStatus: this.status,
          documentation: this.documentation,
          action: this.action
        })
      },

      cancel () {
        this.$emit ('cancel')
      }
    }
  }
</script>
