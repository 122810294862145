<messages>["../Services"]</messages>

<template>
  <confirmation-dialog
    v-model="dialogState"
    :custom-btn-text="$t(`view.label.sendEmail.send`)"
    :headline="$t(`view.label.sendEmail.title`, {address})"
    :is-loading="isLoading"
    :error-msg="errorText"
    @ok="submit">
    <template #activator="{ on }">
      <slot name="activator" :on="on"/>
    </template>
    <p>{{ $t(`view.label.sendEmail.confirm`, {address}) }}</p>
  </confirmation-dialog>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  const ALREADY_SENT = 'already scheduled to be sent'
  const WAS_SENT = 'was just sent'
  const STATUS_VALID = 'already been verified'

  export default {
    components: {
      ConfirmationDialog
    },

    props: {
      address: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        isLoading: false,
        errorMsg: null,
        dialogState: false
      }
    },

    computed: {
      errorText () {
        if (!this.errorMsg) return null

        if (this.errorMsg.includes (ALREADY_SENT)) {
          return this.$t ('view.label.sendEmail.error.scheduled')
        } else if (this.errorMsg.includes (WAS_SENT)) {
          return this.$t ('view.label.sendEmail.error.wasSend')
        } else if (this.errorMsg.includes (STATUS_VALID)) {
          return this.$t ('view.label.sendEmail.error.status')
        } else {
          return this.errorMsg
        }
      }
    },

    watch: {
      dialogState (newVal) {
        if (!newVal) {
          this.errorMsg = null
        }
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      submit () {
        this.isLoading = true
        this.errorMsg = null

        this.fetchData ({
          op: 'emailstatus/verify',
          params: {
            email: this.address
          },
          cb: () => {
            this.displaySuccessMessage (
              this.$t ('view.label.sendEmail.success', {address: this.address}))

            this.dialogState = false
          },
          cbError: (d, next) => {
            if (d.errors.length) {
              const error = d.errors[0]
              if (error.fieldErrors && error.fieldErrors['email.address']) {
                this.errorMsg = error.fieldErrors['email.address'].message
              } else {
                this.errorMsg = error.message
              }
            }
            next (d)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
