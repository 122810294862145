<messages>["./Services"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <base-layout mw2>
      <v-col
        id="email"
        cols="12" sm="6">
        <email-card
          :loading="isLoading"
          @submit="submit"
          @reset="reset"/>
      </v-col>
    </base-layout>

    <base-layout mw2>
      <v-col
        id="status"
        cols="12">
        <v-slide-y-transition mode="out-in">
          <email-status
            v-if="isLoading || result || notFound"
            :is-loading="isLoading"
            :can-edit="canEdit"
            :data="result"
            :email="submittedAddress"
            :not-found="notFound"
            @created="scrollTo"
            @edit="editEmail"/>
        </v-slide-y-transition>
      </v-col>
      <v-col
        id="edit"
        cols="12">
        <v-slide-y-transition mode="out-in">
          <email-status-edit
            v-if="edit && canEdit"
            :data="result"
            :is-loading="isLoadingEdit"
            :admin="admin"
            @cancel="cancelEdit"
            @created="scrollTo"
            @submit="submitChange"/>
        </v-slide-y-transition>
      </v-col>
    </base-layout>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions} from 'vuex'
  import goTo from 'vuetify/lib/services/goto'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import EmailCard from './EmailCard'
  import EmailStatus from './EmailStatus'
  import EmailStatusEdit from './EmailStatusEdit'

  export default {
    name: 'EmailVerification',

    components: {
      BaseLayout,
      EmailCard,
      EmailStatus,
      EmailStatusEdit
    },

    data () {
      return {
        isLoading: false,
        isLoadingEdit: false,
        result: null,
        edit: false,
        notFound: false,
        submittedAddress: null
      }
    },

    computed: {
      canEdit () {
        const permissions = this.$store.getters['auth/permissions']
        const clientId = this.$store.getters['auth/actingClientId']
        return permissions.includes ('ManageAllObjects') ||
          (permissions.includes ('ManageOwnObjects') && (
            this.result ? this.result.clientId === clientId : true
          ) && (
            this.result ? this.result.status !== 'Valid' : false
          ))
      },

      admin () {
        const permissions = this.$store.getters['auth/permissions']
        return permissions.includes ('ManageAllObjects')
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      submit (email) {
        this.notFound = false
        this.edit = false
        this.result = null
        this.isLoading = true
        this.submittedAddress = email
        this.scrollTo ('#status')
        const request = {
          op: 'emailstatus/check',
          params: {
            email: email
          },
          cb: data => {
            this.result = data
          },
          cbError: () => {
            this.notFound = true
          },
          cbFinal: () => {
            this.isLoading = false
          }
        }

        this.fetchData (request)
      },

      submitChange (changes) {
        this.isLoadingEdit = true
        const request = {
          op: 'emailstatus/change',
          params: {
            ...changes
          },
          cb: data => {
            this.result = data
            this.scrollTo ('#status')
            this.edit = false
          },
          cbFinal: () => {
            this.isLoadingEdit = false
          }
        }

        this.fetchData (request)
      },

      reset () {
        this.scrollTo ('#email')
        this.result = null
        this.isLoading = false
        this.edit = false
      },

      editEmail () {
        this.edit = true
        this.scrollTo ('#edit')
      },

      scrollTo (name) {
        goTo (name)
      },

      cancelEdit () {
        this.edit = false
        this.scrollTo ('#status')
      }
    }
  }
</script>
