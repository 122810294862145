<messages>["./Services"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-card
    height="100%"
    width="100%">
    <form @submit.prevent="submit()">
      <v-card-title primary-title>
        <div>
          <div
            v-t="'view.label.email'"
            class="text-h5"/>
          <div
            v-t="'view.label.titleDescription'"
            class="cgwng-subheading"/>
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model.trim="email"
          name="email"
          label="E-Mail"
          :error-messages="validationErrors(
            'email',
            {
              required: 'required.email',
              email: 'general.invalid.email'
            })"
          single-line
          @blur="$v.email.$touch()"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.reset'"
          text
          @click="reset()"/>
        <v-btn
          v-t="'general.button.submit'" color="primary" type="submit"
          :loading="loading"/>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
  import {required} from 'vuelidate/lib/validators'
  import {email} from '@/app/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  export default {
    name: 'EmailCard',

    mixins: [validationMixins],

    props: {
      loading: {
        type: Boolean,
        defautl: false
      }
    },

    data () {
      return {
        email: ''
      }
    },

    validations: {
      email: {required, email}
    },

    computed: {
      emailQuery: {
        get () {
          return this.$route.query.email
        },
        set (val) {
          this.$router.replace ({query: {email: val}})
        }
      }
    },

    mounted () {
      if (this.emailQuery) {
        this.email = this.emailQuery
        this.submit ()
      }
    },

    methods: {
      submit () {
        if (!this.$v.$invalid) {
          this.emailQuery = this.email
          this.$emit ('submit', this.email)
        } else {
          this.$v.$touch ()
        }
      },

      reset () {
        this.email = ''
        this.$v.email.$reset ()
        this.$emit ('reset')
      }
    }
  }
</script>
