<messages>["./Services"]</messages>
<template>
  <v-row class="fill-height" justify="space-between" align="center">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-icon :color="color(old).color" v-on="on">
          {{ color(old).icon }}
        </v-icon>
      </template>
      <span v-t="`status.${old}`"/>
    </v-tooltip>
    <v-icon>trending_flat</v-icon>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-icon :color="color(newState).color" v-on="on">
          {{ color(newState).icon }}
        </v-icon>
      </template>
      <span v-t="`status.${newState}`"/>
    </v-tooltip>
  </v-row>
</template>

<script>
  export default {
    props: {
      old: {
        type: String,
        required: true
      },
      newState: {
        type: String,
        required: true
      },
      compact: {
        type: Boolean,
        default: false
      },
      color: {
        type: Function,
        default: () => ({icon: '', color: 'grey'})
      }
    }
  }
</script>
